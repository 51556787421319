body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.chats-cnt {
  max-height: 70vh;
}

@media only screen and (max-height: 900px) {
  .chats-cnt {
    max-height: 60vh
  }
  .download-btn {
    max-width: 70vw;
    overflow: auto;
  }
}
@media only screen and (max-height: 750px) {
  .chats-cnt {
    max-height: 55vh
  }
}

@media only screen and (max-width: 650px) {
  .content {
    padding: 10px !important;
    max-width: 99% !important;
  }
  .channel-inpts-cnt {
    margin-bottom: 20px;
  }
  .channel-menu {
    align-items: flex-start !important;
  }
  .channel-menu-right {
    gap: 5px !important;
  }
  .chatListItem {
    padding: 10px !important;
  }
  .channel-inpts-uploadBtn {
    min-width: auto !important;
  }
  .channel-inpts-cnt .ant-upload-wrapper {
    max-width: 50% !important;
  }
  .channel-inpts-cnt .ant-upload-list-item-container {
    max-width: 34vw !important;
  }
}
.alph-text {
  font-size: 1.1rem;
}
.alph-text code {
  margin: 0;
}

@keyframes warning {
  0% {
    color: #fff;
  }
  50% {
    color: #cf1322;
  }
  100% {
    color: #fff,
  }
}

.armedH1 {
  animation: warning 1.4s infinite;
  animation-timing-function: steps(1, jump-end);
}
.channel-inpts-cnt .ant-upload-wrapper {
  display: flex;
  align-items: center;
}
.channel-inpts-cnt .ant-upload.ant-upload-select {
  height: 100%;
}
.channel-inpts-cnt .ant-upload-list-item-container {
  margin-bottom: 5px;
  margin-left: 5px;
}